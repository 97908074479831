export default {
  name: 'Parceria',
  data () {
    return {
      parceria: {
        imagem: 'home/parceria-volkswagen.webp',
        icone: 'home/logo-volkswagen.png'
      }
    }
  }
}
